var OnScreen = require('onscreen');
var os = new OnScreen({
    tolerance: 0,
    debounce: 100
});
$(function() {
    //Counter
    $('.count').each(function () {
        var totalcount = Number($(this).html());
        $(this)
            .html('0')
            .data('count',totalcount)
            .data('counted',false);
    });
    os.on('enter', '.count', function(element, event){
        if( !Boolean($(element).data('counted')) ) {
            $(element)
                .countTo({
                    from: 0,
                    to: Number($(element).data('count')),
                    speed: 1500
                })
                .data('counted', true);
        }
    });
});