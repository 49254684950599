var $ = require('jquery');
require('bootstrap');
require('jquery-countto');
require('./utilities/class_enlarge.js');
require('./utilities/class_count.js');
require('./utilities/pdw_mobile_menu.js');

$(function() {

    //Resize logo on scroll
    /*
    window.onscroll = function() {
        resizeLogo()
    };

    function resizeLogo() {
        var Logo = document.getElementById("logo")
        if (document.body.scrollTop > 5 || document.documentElement.scrollTop > 5) {
            Logo.style.width = '200px';
        } else {
            Logo.style.width = '325px';
        }
    }
    */

});