$(function() {
    $('.pdw-mobile-menu-close').on('click',function (e) {
        $('.pdw-mobile-menu').removeClass('show');
    });
    $('.pdw-mobile-menu-open').on('click',function (e) {
        $('.pdw-mobile-menu').addClass('show');
    });
    $('.pdw-mobile-menu-toggler').on('click',function (e) {
        $('.pdw-mobile-menu').toggleClass('show');
    });
});