$(function() {
    //Enlarge column
    //Enlarge left
    $('.enlarge-l').each(function () {
        var thiswidth = $(this).width();
        var thisml = parseInt($(this).css('marginLeft'));
        var thismr = parseInt($(this).css('marginRight'));
        if(thisml > 0){
            thisml = 0;
        }
        if(thismr > 0){
            thismr = 0;
        }
        var thism=-thismr-thisml;
        var width = document.querySelector('#content > .container').clientWidth;
        var fullwidth = $(document).width();
        $(this).css({
            marginLeft: (width-fullwidth)/2,
            width: 'calc( ' + String((100/(width/thiswidth))) + '% + ' + String(((fullwidth-width)/2)+thism) + 'px )',
            maxWidth: 'unset'
        });
    });
    //Enlarge right
    $('.enlarge-r').each(function () {
        var thiswidth = $(this).width();
        var thisml = parseInt($(this).css('marginLeft'));
        var thismr = parseInt($(this).css('marginRight'));
        if(thisml > 0){
            thisml = 0;
        }
        if(thismr > 0){
            thismr = 0;
        }
        var thism=-thismr-thisml;
        var width = document.querySelector('#content > .container').clientWidth;
        var fullwidth = $(document).width();
        $(this).css({
            marginRight: (width-fullwidth)/2,
            width: 'calc( ' + String((100/(width/thiswidth))) + '% + ' + String(((fullwidth-width)/2)+thism) + 'px )',
            maxWidth: 'unset'
        });
    });
    window.onresize = function(event) {
        //Enlarge left
        $('.enlarge-l').each(function () {
            $(this).removeAttr("style");
            var thiswidth = $(this).width();
            var thisml = parseInt($(this).css('marginLeft'));
            var thismr = parseInt($(this).css('marginRight'));
            if(thisml > 0){
                thisml = 0;
            }
            if(thismr > 0){
                thismr = 0;
            }
            var thism=-thismr-thisml;
            var width = document.querySelector('#content > .container').clientWidth;
            var fullwidth = $(document).width();
            $(this).css({
                marginLeft: (width-fullwidth)/2,
                width: 'calc( ' + String((100/(width/thiswidth))) + '% + ' + String(((fullwidth-width)/2)+thism) + 'px )',
                maxWidth: 'unset'
            });
        });
        //Enlarge right
        $('.enlarge-r').each(function () {
            $(this).removeAttr("style");
            var thiswidth = $(this).width();
            var thisml = parseInt($(this).css('marginLeft'));
            var thismr = parseInt($(this).css('marginRight'));
            if(thisml > 0){
                thisml = 0;
            }
            if(thismr > 0){
                thismr = 0;
            }
            var thism=-thismr-thisml;
            var width = document.querySelector('#content > .container').clientWidth;
            var fullwidth = $(document).width();
            $(this).css({
                marginRight: (width-fullwidth)/2,
                width: 'calc( ' + String((100/(width/thiswidth))) + '% + ' + String(((fullwidth-width)/2)+thism) + 'px )',
                maxWidth: 'unset'
            });
        });
    };
});